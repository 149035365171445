import MyMenu from "./MyMenu";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useState } from "react";
import { useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import ReportService from "../services/ReportService";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { MultiSelect } from 'primereact/multiselect';

const ExcelExporter = () => {

    let starDateHour = new Date();
    starDateHour.setHours(0)
    starDateHour.setMinutes(0)

    const [starDate, setStartDate] = useState(starDateHour);
    const [endDate, setEndDate] = useState(new Date());
    const [tempStarDate, settempStartDate] = useState(starDateHour);
    const [tempEndDate, setTempEndDate] = useState(new Date());
    const [planId, setPlanId] = useState("");
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isPlanIdTextValid, setIsPlanIdTextValid] = useState(false);
    const [selectedSensorIds, setSelectedSensorIds] = useState([]);
    const [sensorIdList, setSensorIdList] = useState(null);
    const [isSensorButtonEnabled, setIsSensorButtonEnabled] = useState(false);
    const [isTempSensorButtonEnabled, setIsTempSensorButtonEnabled] = useState(false);


    const [tempSensorIds, setTempSensorIds] = useState(null);
    const [tempSensorIdList, setTempSensorIdList] = useState(null);
    const [selectedTempSensorIds, setSelectedTempSensorIds] = useState([]);
    const [minute, setMinute] = useState(30);

    useEffect(() => {
        getSensorData();
    }, [])

    const getSensorData = () => {
        ReportService.getTelematikVehicle().then(res => {
            const validSensorIds = res.filter(vehicle => vehicle._sensorId !== '');
            const validSensorIdsOptions = [];
            validSensorIds.forEach(element => {
                validSensorIdsOptions.push({ name: element.lincensePlate, code: element._sensorId })
            });
            console.log(validSensorIdsOptions)
            const validTempSensorIds = res.filter(vehicle => vehicle._temperatureSensorId !== '');
            const validTempSensorIdsOptions = [];
            validTempSensorIds.forEach(element => {
                validTempSensorIdsOptions.push({ name: element.lincensePlate, code: element._temperatureSensorId })
            });
            console.log(validTempSensorIdsOptions)

            setSensorIdList(validSensorIdsOptions);
            setTempSensorIdList(validTempSensorIdsOptions)
        }).catch(err => {
            console.log("getSensorIds errror , ")
            console.log(err)
        })
    }


    const downloadSelectedSensors = () => {

        const idList = selectedSensorIds.map(el => el.code).join(",");

        ReportService.getSensorHistoryExcel(starDate, endDate, idList)


    }

    
    const downloadSelectedTempSensors = () => {

        const idList = selectedTempSensorIds.map(el => el.code).join(",");

        ReportService.getTempSensorHistoryExcel(starDate, endDate, idList, minute)


    }

    const excelExportVehicles = () => {
        ReportService.getTelematikVehicleExcelExport();

    }

    const setSensorValues = (value) => {


        if (value.length > 10) {
            alert("10 tane plakadan fazla seçim yapamazsınız.")
            return;
        }

        setSelectedSensorIds(value);
        if ( value.length > 0 && value.length < 11) {
            setIsSensorButtonEnabled(true)
        }
    }

    const setTempSensorValues = (value) => {


        if (value.length > 10) {
            alert("10 tane plakadan fazla seçim yapamazsınız.")
            return;
        }

        setSelectedTempSensorIds(value);
        if ( value.length > 0 && value.length < 11) {
            setIsTempSensorButtonEnabled(true)
        }
    }


    const planIdInputChange = (e) => {
        setPlanId(e.target.value)

        if (e.target.value == "") {
            setIsButtonEnabled(false);
            setIsPlanIdTextValid(false);
        } else {
            setIsButtonEnabled(true);
            setIsPlanIdTextValid(true);
        }

    }

    return (
        <>
            <MyMenu></MyMenu>
            <Accordion activeIndex={0} >
                <AccordionTab header="Realizasyonu Excele Aktarma">
                    <p className="m-0">
                        <div className="p-inputgroup justify-content-center gap-5 mt-7">
                            <div className="w-1">
                                <FloatLabel>
                                    <InputTextarea rows={20} invalid={!isPlanIdTextValid} placeholder="Her  Satıra 1 planId giriniz" id="plan_id" onChange={(e) => planIdInputChange(e)} />
                                    <label htmlFor="plan_id">Plan Id</label>
                                </FloatLabel>
                            </div>
                            <div className="w-3">
                                <FloatLabel >
                                    <Calendar id="start_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={starDate} onChange={(e) => setStartDate(e.value)} showButtonBar />
                                    <label htmlFor="start_date">Başlangıç Tarihi</label>
                                </FloatLabel>
                            </div>
                            <div className="w-3">
                                <FloatLabel>
                                    <Calendar id="end_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={endDate} onChange={(e) => setEndDate(e.value)} showButtonBar />
                                    <label htmlFor="end_date">Bitiş Tarihi</label>
                                </FloatLabel>
                            </div>
                            <div className="w-max">
                                {isButtonEnabled && <Button label="İndir" onClick={() => findPlans()} />}
                                {!isButtonEnabled && <Button label="İndir" disabled />}
                            </div>
                        </div>
                    </p>
                </AccordionTab>
                <AccordionTab header="Trio Mobil Araç Listesini Excele Aktarma">
                    <p className="m-0">
                        <div className="w-max">
                            <Button label="İndir" onClick={() => excelExportVehicles()} />
                        </div>
                    </p>
                </AccordionTab>
                <AccordionTab header="Sıcaklık Ölçüm Geçmişini Excele Aktarma">
                    <div className="p-inputgroup justify-content-center gap-5 mt-7">

                        <div className="w-5">
                            <FloatLabel>
                                <MultiSelect filter value={selectedTempSensorIds} onChange={(e) => setTempSensorValues(e.value)} options={tempSensorIdList} optionLabel="name" placeholder="Araç Seç" maxSelectedLabels={10} className="w-full md:w-20rem" />
                            </FloatLabel>
                        </div>
                        <div className="w-2">
                            <FloatLabel >
                                <label htmlFor="minmax-buttons" className="font-bold block mb-2">Veri Sıklığı Filtresi - Dakika </label>
                                <InputNumber inputId="minmax-buttons" value={minute} onValueChange={(e) => setMinute(e.value)} mode="decimal" showButtons min={0} max={120} />
                            </FloatLabel>
                        </div>
                        <div className="w-0">
                            <FloatLabel >
                                <Calendar id="start_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={starDate} onChange={(e) => setStartDate(e.value)} showButtonBar />
                                <label htmlFor="start_date">Başlangıç Tarihi</label>
                            </FloatLabel>
                        </div>
                        <div className="w-2">
                            <FloatLabel>
                                <Calendar id="end_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={endDate} onChange={(e) => setEndDate(e.value)} showButtonBar />
                                <label htmlFor="end_date">Bitiş Tarihi</label>
                            </FloatLabel>
                        </div>
                        <div className="w-max">
                            {isTempSensorButtonEnabled && <Button label="İndir" onClick={() => downloadSelectedTempSensors()} />}
                            {!isTempSensorButtonEnabled && <Button label="İndir" disabled />}
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header="Sensör Ölçüm Geçmişini Excele Aktarma">

                    <div className="p-inputgroup justify-content-center gap-5 mt-7">

                        <div className="w-5">
                            <FloatLabel>
                                <MultiSelect filter value={selectedSensorIds} onChange={(e) => setSensorValues(e.value)} options={sensorIdList} optionLabel="name" placeholder="Araç Seç" maxSelectedLabels={10} className="w-full md:w-20rem" />
                            </FloatLabel>
                        </div>
                        <div className="w-2">
                            <FloatLabel >
                                <Calendar id="start_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={starDate} onChange={(e) => setStartDate(e.value)} showButtonBar />
                                <label htmlFor="start_date">Başlangıç Tarihi</label>
                            </FloatLabel>
                        </div>
                        <div className="w-2">
                            <FloatLabel>
                                <Calendar id="end_date" showIcon showTime hourFormat="24" dateFormat="dd.mm.yy" value={endDate} onChange={(e) => setEndDate(e.value)} showButtonBar />
                                <label htmlFor="end_date">Bitiş Tarihi</label>
                            </FloatLabel>
                        </div>
                        <div className="w-max">
                            {isSensorButtonEnabled && <Button label="İndir" onClick={() => downloadSelectedSensors()} />}
                            {!isSensorButtonEnabled && <Button label="İndir" disabled />}
                        </div>
                    </div>

                </AccordionTab>
            </Accordion>
        </>
    )


}


export default ExcelExporter;